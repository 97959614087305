import React from "react"
import { FaArrowCircleUp } from "react-icons/fa"
import { isBrowser } from "../constants/isBrowser"

const BackTopArrow = ({ showLink }) => {
  if (isBrowser() === true) {
    var scrollToTop = () => {
      window.scrollTo({
        left: 0,
        top: 0,
      })
    }
  }

  return (
    <button
      className={`${showLink ? "top-link show-link" : "top-link"}`}
      onClick={scrollToTop}
    >
      <FaArrowCircleUp />
    </button>
  )
}

export default BackTopArrow
